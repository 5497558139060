import _ from 'lodash';
import React from 'react';
import 'whatwg-fetch';

import { fetchApi, getImageSrc, getDownloadSrc } from '../utils';

const styles = {
  headers: {
    minHeight: 195,
  },
  container: {
    margin: '0 auto',
    maxWidth: 1300,
    minWidth: 375,
  },
  viewer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    clear: 'both',
  },
  downloadAll: {
    float: 'right',
    margin: 5,
  }
};

const triggerDownload = (file) => {
  const a = document.createElement('a');
  a.href = getDownloadSrc(file, file.tenant_id);
  a.dispatchEvent(new MouseEvent('click'));
};

class FilePreview extends React.Component {
  render() {
    const { file, size = 'medium' } = this.props;
    return (
      <div style={{
        display: 'inline-block', padding: 15, textAlign: 'center', cursor: 'pointer',
      }} className="file-viewer">
        <div onClick={() => {
          triggerDownload(file);
        }}>
          <div>{file.file_display_name}</div>
          <div style={{
            width: 256, height: 256, margin: 3,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url(${file.is_image ? getImageSrc(file, size) : '/images/404.png'})`,
            backgroundSize: 'contain',
          }}>
          </div>
          <button className="button">Download</button>
        </div>
      </div>
    );
  }
}

class FileExplorer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
    };
    _.bindAll(this, ['onDownloadAll']);
  }

  loadData() {
    const { email_id } = this.props;
    fetchApi('email/attachments-explorer', { email_id }).then((response) => {
      const {
        email = {}, order_template = {},
      } = _.get(response, 'json') || {};
      this.setState({
        email,
        order_template,
        loaded: true,
      });
    });
  }

  componentDidMount() {
    this.loadData();
  }

  onDownloadAll() {
    const download = (files) => {
      if (files.length === 0) {
        return;
      }
      triggerDownload(files[0]);
      setTimeout(() => {
        download(files.slice(1));
      }, 500);
    };
    download(_.get(this.state, 'email.attachments') || []);
  }

  render() {
    const { email, order_template, loaded } = this.state;

    if (!loaded) {
      return <div style={styles.loading}></div>;
    }

    const { attachments, subject } = email;
    const { header, avatar, order_header_name } = order_template;

    return (
      <div style={styles.container}>
        <div style={{
          ...styles.headers,
          backgroundImage: `url(${header.file_name_headers})`,
          backgroundSize: 'cover',
        }}>
          <div style={{ display: 'inline-block' }}>
            <img src={getImageSrc(avatar, 'medium')} style={{width: 140, height: 140, margin: 27}}/>
          </div>
          <div style={{
            display: 'inline-block', marginLeft: 27, color: '#FFF',
            textShadow: '0px 2px 25px rgba(0,0,0,0.85)',
          }}>
            <div style={{ fontSize: '2.0rem', fontWeight: 400 }}>{order_header_name} - Email Attachments</div>
            <div style={{ paddingTop: 10 }}>{subject}</div>
          </div>
        </div>
        <div style={styles.viewer}>
          {attachments.map((file) => <FilePreview key={file.file_id} file={file}/>)}
        </div>
      </div>
    );
  }
}

const FileExplorerContainer = () => {
  const params = {};
  window.location.search.replace('?', '').split('&').map(function (part){
      var parts = part.split('=');
      params[parts[0]] = decodeURIComponent(parts[1]);
  });

  return <FileExplorer email_id={params.email_id}/>;
};

export default FileExplorerContainer;
